// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.esbModalToggle:hover {
    cursor: pointer;
}
button.esbModalCancel, button.esbModalSave, button.esbModalClose {
    justify-self: end;
    align-self: start;
    margin-left: 1rem;
}
.esbModal--backDrop {
    background-color: #00000080;
    z-index: 10;
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.esbModal--container {
    position: fixed;
    z-index: 20;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    border-radius: 4px;
    grid-template-columns: minmax(100px, 1fr);
    padding: 0;
    background-color: #fff;
    min-width: 90vw;
    max-height: 100vh;
    overflow-y: auto;
}
.esbModal--content div {
    min-width: 0;
    overflow-wrap: break-word;
}
.esbModal--header, .esbModal--content, .esbModal--footer {
    display: grid;
    padding: 1.5rem;
}
.esbModal--header:empty {
    display: none;
}
.esbModal--content {
    border-top: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
}
.esbModal--footer {
    grid-template-columns: 1fr auto;
}
esb-modal .esbModal--header h5 {
    margin: 0;
}
@media (min-width: 920px) {
    .esbModal--container {
        min-width: 30vw;
    }
}
`, "",{"version":3,"sources":["webpack://./src/modals/modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,2BAA2B;IAC3B,WAAW;IACX,eAAe;IACf,cAAc;IACd,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;AACb;AACA;IACI,eAAe;IACf,WAAW;IACX,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,UAAU;IACV,sBAAsB;IACtB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,6BAA6B;IAC7B,gCAAgC;AACpC;AACA;IACI,+BAA+B;AACnC;AACA;IACI,SAAS;AACb;AACA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".esbModalToggle:hover {\n    cursor: pointer;\n}\nbutton.esbModalCancel, button.esbModalSave, button.esbModalClose {\n    justify-self: end;\n    align-self: start;\n    margin-left: 1rem;\n}\n.esbModal--backDrop {\n    background-color: #00000080;\n    z-index: 10;\n    position: fixed;\n    overflow: auto;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n}\n.esbModal--container {\n    position: fixed;\n    z-index: 20;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    display: grid;\n    border-radius: 4px;\n    grid-template-columns: minmax(100px, 1fr);\n    padding: 0;\n    background-color: #fff;\n    min-width: 90vw;\n    max-height: 100vh;\n    overflow-y: auto;\n}\n.esbModal--content div {\n    min-width: 0;\n    overflow-wrap: break-word;\n}\n.esbModal--header, .esbModal--content, .esbModal--footer {\n    display: grid;\n    padding: 1.5rem;\n}\n.esbModal--header:empty {\n    display: none;\n}\n.esbModal--content {\n    border-top: 1px solid #c1c1c1;\n    border-bottom: 1px solid #c1c1c1;\n}\n.esbModal--footer {\n    grid-template-columns: 1fr auto;\n}\nesb-modal .esbModal--header h5 {\n    margin: 0;\n}\n@media (min-width: 920px) {\n    .esbModal--container {\n        min-width: 30vw;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
