import config from 'config';
import { i18n } from 'esi18n';
import get from 'lodash-es/get';
import renderingContext from '@entryscape/rdforms/src/view/renderingContext';
import ItemStore from '@entryscape/rdforms/src/template/ItemStore';
import bundleLoader from '@entryscape/rdforms/src/template/bundleLoader';
import utils from '@entryscape/rdforms/src/utils';
import '@entryscape/rdforms/src/view/jquery/all';

import { namespaces, Graph } from '@entryscape/rdfjson';
import { EntryStore, EntryStoreUtil } from '@entryscape/entrystore-js';
import {registerHandler} from 'blocks/error/errorHandler';
import registry from 'blocks/registry';
import { getFallbackBundleUrls } from 'blocks/utils/bundleUtil';
import DOMUtil from 'blocks/utils/htmlUtil';
import { LookupStore } from 'entitytype-lookup';

const fixEntryOrGraph = (entryOrGraph, uri) => {
  if (!entryOrGraph.getAllMetadata) {
    return { g: entryOrGraph, r: uri };
  }
  return { g: entryOrGraph.getAllMetadata(), r: entryOrGraph.getResourceURI() };
};

/** @type {store/EntryStore} */
let es;
const dialogsContainerNode = DOMUtil.create('div', { id: 'entryscape_dialogs', class: 'entryscape' }, document.body);
renderingContext.setPopoverContainer(dialogsContainerNode);
registry.set('renderingContext', renderingContext);

const synchronousGetCurrentModule = () => {
  /**
   * Callback funktion for getting module from context info. !!The context entry must be loaded before lookupStore is
   * used for this to work
   *
   * @param {Context} context
   * @returns {string|undefined}
   */
  const getCurrentModule = (context) => {
    if (!context) return;
    const type2module = {
      'http://entryscape.com/terms/CatalogContext': 'catalog',
      'http://entryscape.com/terms/WorkbenchContext': 'workbench',
      'http://entryscape.com/terms/TerminologyContext': 'terms',
    };
    const contextEntry = context.getEntry(true); // TODO! getEntry async when lookupStore supports async callback
    const graph = contextEntry.getEntryInfo().getGraph();
    const contextResourceURI = contextEntry.getResourceURI();
    const typeMatch = Object.keys(type2module).find((type) => {
      const match = graph.find(contextResourceURI, 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type', type);
      return match.length > 0;
    });
    if (!typeMatch) return;
    return type2module[typeMatch];
  };
  return getCurrentModule;
};

const init = {
  config() {
    config.get = get.bind(null, config);
  },
  entrystore() {
    es = new EntryStore((config.entrystore && config.entrystore.repository ? config.entrystore.repository : null));
    es.setRequestCachePrevention(true);
    es.getREST().disableCredentials();
    es.getREST().disableJSONP();
    registry.set('entrystore', es);
    registry.set('entry', undefined);
    registry.set('entrystoreutil', new EntryStoreUtil(es));
  },
  namespaces() {
    // config.rdf section
    config.rdf = config.rdf || {};
    // Default registration of namespaces
    registry.set('namespaces', namespaces);
    if (config.rdf.namespaces) {
      namespaces.add(config.rdf.namespaces);
    }
    namespaces.add('store', 'http://entrystore.org/terms/');
  },
  itemstore: {
    async bundles() {
      const items = new ItemStore();
      items.handleErrorAs = 'error';
      const bundles = [];
      // load default bundles => need to be in the webpack bundle
      if (config.itemstore) {
        if (config.itemstore.defaultBundles) { // somehow register only what set on itemstore.defaultBundles
          const commontemplateModule = await import(/* webpackChunkName: "commonTemplates" */ './commonTemplates');
          const ct = commontemplateModule.default;
          bundles.push(ct.skosBundle);
          bundles.push(ct.dctermsBundle);
          bundles.push(ct.foafBundle);
          bundles.push(ct.vcardBundle);
          bundles.push(ct.odrsBundle);
          bundles.push(ct.dcatPropsBundle);
          bundles.push(ct.dcatBundle);
          bundles.push(ct.geoDcatVocabsBundle);
          bundles.push(ct.geoDcatPropsBundle);
          bundles.push(ct.geoDcatBundle);
          bundles.push(ct.escBundle);
        }
        if (config.itemstore.bundles) {
          config.itemstore.bundles.forEach(id => bundles.push(getFallbackBundleUrls(id)));
        }
        await bundleLoader(items, bundles); // load the bundles
        registry.set('itemstore', items);
      }
    },
    languages() {
      if (config.itemstore) {
        if (config.itemstore.languages) {
          renderingContext.setLanguageList(config.itemstore.languages);
        }
      }
    },
    appendLanguages() {
      if (config.itemstore) {
        if (config.itemstore.appendLanguages) {
          const langs = renderingContext.getLanguageList();
          renderingContext.setLanguageList(langs.concat(config.itemstore.appendLanguages));
        }
      }
    },
    choosers() {
      if (config.itemstore && config.itemstore.choosers) {
        config.itemstore.choosers.forEach((chooserName) => {
          import(
            /* webpackInclude: /.*Chooser\.js$/ */
            /* webpackMode: "eager" */
            `blocks/metadata/choosers/${chooserName}.js`)
            .then((chooser) => {
              try {
                chooser.default.registerDefaults();
              } catch (e) {
                throw Error(`Could not load chooser ${chooserName}`);
              }
            });
        });
      }
    },
  },
  entrychooser() {
    if (config.entrychooser) {
      registry.set('entrychooser', config.entrychooser);
    }
  },
  rdfutils() {
    let labelProperties;
    let descriptionProperties;
    const rdfutils = {
      setLabelProperties(arr) {
        labelProperties = arr;
      },
      setDescriptionProperties(arr) {
        descriptionProperties = arr;
      },
      getLabel(entryOrGraph, uri) {
        const { g, r } = fixEntryOrGraph(entryOrGraph, uri);
        return utils.getLocalizedValue(
          utils.getLocalizedMap(g, r, labelProperties)).value;
      },
      getLabelMap(entryOrGraph, uri) {
        const { g, r } = fixEntryOrGraph(entryOrGraph, uri);
        return utils.getLocalizedMap(g, r, labelProperties);
      },
      getDescription(entryOrGraph, uri) {
        const { g, r } = fixEntryOrGraph(entryOrGraph, uri);
        return utils.getLocalizedValue(
          utils.getLocalizedMap(g, r, descriptionProperties)).value;
      },
      getDescriptionMap(entryOrGraph, uri) {
        const { g, r } = fixEntryOrGraph(entryOrGraph, uri);
        return utils.getLocalizedMap(g, r, descriptionProperties);
      },
    };

    if (config.rdf.labelProperties) {
      rdfutils.setLabelProperties(config.rdf.labelProperties);
    } else {
      rdfutils.setLabelProperties([
        'foaf:name',
        ['foaf:firstName', 'foaf:lastName'],
        ['foaf:givenName', 'foaf:familyName'],
        'vcard:fn',
        'skos:prefLabel',
        'dcterms:title',
        'dc:title',
        'rdfs:label',
        'skos:altLabel',
        'vcard:hasEmail',
      ]);
    }

    if (config.rdf.descriptionProperties) {
      rdfutils.setDescriptionProperties(config.rdf.descriptionProperties);
    } else {
      rdfutils.setDescriptionProperties([
        'http://purl.org/dc/terms/description',
      ]);
    }

    registry.set('rdfutils', rdfutils);
  },
  asyncHandler() {
    registerHandler();
  },
  nlsOverride() {
    if (typeof config.locale.NLSOverrides === 'object') {
      i18n.setOverrides(config.locale.NLSOverrides);
    }
  },
  locale() {
    registry.set('localize', lang2val => utils.getLocalizedValue(lang2val).value);

    // Fix defaultLocale and locale
    registry.set('defaultLocale', i18n.getLocale());
    registry.onChange('locale', (l) => {
      if (i18n.getLocale() !== l) {
        i18n.setLocale(l);
      }
    });

    registry.onChange('locale', () => {
      // Combining the current language, with accepted languages from the browser and finally the
      // languages supported by entryscape. The given order should be respected and no duplicates
      // allowed.
      const primary = new Set();
      primary.add(registry.get('locale'));
      const alo = registry.get('clientAcceptLanguages');
      /* eslint-disable no-confusing-arrow */
      if (alo) {
        Object.keys(alo).sort((l1, l2) => alo[l1] < alo[l2] ? 1 : -1).forEach(l => primary.add(l));
      }
      config.locale.supported.map(o => o.lang).forEach(l => primary.add(l));
      renderingContext.setPrimaryLanguageCodes(Array.from(primary));
    });
  },
  lookupStore() {
    const { entitytypes: entityTypeConfigs, projecttypes: projectTypeConfigs } = config;
    const entryStore = registry.get('entrystore');
    const itemStore = registry.get('itemstore');
    const getCurrentModule = synchronousGetCurrentModule();

    const lookupStore = new LookupStore({
      entityTypeConfigs,
      projectTypeConfigs,
      entryStore,
      itemStore,
      getCurrentModule,
    });
    registry.set('lookupstore', lookupStore);
  },
};

init.config();
init.entrystore();
init.namespaces();
init.entrychooser();
init.rdfutils();

export default async () => {
  init.nlsOverride();
  await init.itemstore.bundles();
  init.itemstore.appendLanguages();
  init.itemstore.languages();
  init.itemstore.choosers();
  init.locale();
  init.lookupStore();

  // initApp
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init.asyncHandler);
  } else {
    init.asyncHandler();
  }
};
