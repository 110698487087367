// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.esbClickExpand {
    cursor: pointer;
}

.esbExpanded .esbCollapsed {
    display: none;
}
.esbCollapsed .esbExpanded {
    display: none;
}

.entryscape .esbWideExpandButton.btn {
    text-align: left;
    width:100%
}

.esbWideExpandButton .fas {
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/layout/layout.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB;AACJ;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".esbClickExpand {\n    cursor: pointer;\n}\n\n.esbExpanded .esbCollapsed {\n    display: none;\n}\n.esbCollapsed .esbExpanded {\n    display: none;\n}\n\n.entryscape .esbWideExpandButton.btn {\n    text-align: left;\n    width:100%\n}\n\n.esbWideExpandButton .fas {\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
