import 'regenerator-runtime/runtime';
import 'current-script-polyfill';
import 'mithril';
import 'bootstrap';
import 'blocks/bootstrap.less';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'blocks/migration/list.scss'; // escoList .panel
import 'blocks/migration/errors.css';
import '@selectize/selectize/dist/css/selectize.bootstrap3.css';
import 'blocks/style.css';

import initDefaults from './defaults';

export default initDefaults();
